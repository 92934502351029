<template>
  <div class="teams-wrap">
    <div v-if="teamDetails" class="teams-banner">
      <div
        :style="{ 'background-image': `url(${teamDetails.image})` }"
        class="teams-banner-img-wrap"
        :title="teamDetails.name"
      >
        <div style="height: 100%">
          <div class="d-flex banner-topnav">
            <div v-if="teamDetails.stadiums" class="map-location">
              <v-icon color="#0D0033" size="14" class="mr-2">
                mdi-map-marker
              </v-icon>
              {{ teamDetails.stadiums.name }} | Capacity:
              {{ teamDetails.stadiums.capacity }} –
              <a class="link"> See what’s nearby </a>
            </div>
          </div>
          <div class="teams-name-wrapper">
            <div class="team-info-cont">
              <v-card class="team-info" elevation="0">
                <div class="d-flex align-center justify-center">
                  <img
                    class="logo mr-4"
                    :src="teamDetails.logo"
                    :alt="teamDetails.name"
                  />
                  <div class="team-names">
                    {{ teamDetails.name }}
                  </div>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="teams-wrap">
      <v-skeleton-loader
        height="500"
        v-bind="{ boilerplate: false }"
        type="image"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  name: "TeamBanner",
  computed: {
    teamDetails() {
      return this.$store.state.teams.teamDetails;
    },
  },
};
</script>
<style></style>
