<template>
  <div v-if="info" class="onsale">
    <v-row class="ma-0">
      <v-col cols="12" md="4" sm="12">
        <img
          class="teampic"
          :src="info.image"
          alt="info"
          width="100%"
          height="350px"
        />
      </v-col>
      <v-col cols="12" md="8" sm="12">
        <div class="onsale-info">
          <div v-html="info.description"></div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "TeamTickets",
  props: {
    info: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>
<style></style>
