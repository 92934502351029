<template>
  <div v-if="stadiumDetails" class="venue-details container">
    <v-row class="ma-0">
      <v-col cols="12" md="6" class="address">
        <h2 v-if="stadiumDetails.name" class="stadium-name">
          {{ stadiumDetails.name | capitalize }}
        </h2>
        <div class="d-flex locatedat">
          <img
            class="maplogo mr-3"
            src="@/assets/images/maplogo.png"
            alt="map-icon"
          />
          <span v-if="stadiumDetails.address_line_one">
            {{ stadiumDetails.address_line_one | capitalize }},&nbsp;
          </span>
          <span v-if="stadiumDetails.city_name">
            {{ stadiumDetails.city_name | capitalize }},&nbsp;
          </span>
          <span v-if="stadiumDetails.country">
            {{ stadiumDetails.country | capitalize }},&nbsp;
          </span>
          <span v-if="stadiumDetails.postcode">
            {{ stadiumDetails.postcode | uppercase }}
          </span>
        </div>
        <div v-if="stadiumDetails.capacity" class="d-flex capacity">
          <img class="courticon mr-2" src="@/assets/images/court.png" alt="" />
          <span>
            Stadium Capacity:
            <span class="font-weight-bold">
              {{ stadiumDetails.capacity }}
            </span>
          </span>
        </div>
        <div class="btn-group">
          <v-btn :ripple="false" outlined class="btn-getting mr-2">
            Getting there
            <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
          /></v-btn>
          <v-btn
            :ripple="false"
            :href="stadiumDetails.map_url"
            target="_blank"
            outlined
            class="btn-viewonmap"
          >
            View on map
            <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg" />
          </v-btn>
        </div>
        <div v-if="stadiumDetails.description" class="aboutvenue">
          <div v-html="stadiumDetails.description"></div>
        </div>
      </v-col>
      <v-col id="nearby" cols="12" md="6" class="nearby-panel">
        <div class="d-flex nearby">
          <img
            class="maplogo mr-4"
            src="@/assets/images/locationpin.png"
            alt=""
          />
          <h2 class="nearby-info">What’s nearby</h2>
        </div>
        <div class="nearby-table">
          <v-tabs v-model="navigationTab" left class="nearby-menu">
            <v-tab
              v-for="(place, index) in stadiumDetails.public_nearby"
              :key="index"
              class="name"
              :href="`#${place.id}tab`"
            >
              {{ place.name }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="navigationTab">
            <v-tab-item
              v-for="(place, index) in stadiumDetails.public_nearby"
              :key="index"
              :value="`${place.id}tab`"
              :eager="true"
            >
              <div class="place-list d-flex align-center">
                <span v-if="place.description" class="place-name">
                  {{ place.description }}
                </span>
                <img :src="place.image" alt="" />
                <!-- <span v-if="place.image" class="distance">
                  {{ place.image }}
                </span> -->
                <a
                  :href="place.url"
                  target="_blank"
                  :title="place.url"
                  class="distance"
                >
                  {{ place.url }}
                </a>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "StadiumsLocationAndNearBy",
  data() {
    return {
      navigationTab: null,
      places: [
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
        {
          name: "Places of Interest",
          distance: "0.8 miles",
        },
      ],
    };
  },
  props: {
    stadiumDetails: {
      Type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>

<style></style>
