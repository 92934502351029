<template>
  <div v-if="$store.state.teams.teamDetails" class="manchester-team">
    <v-row class="ma-0">
      <v-col cols="12" md="4" class="filter-and-sellticket">
        <v-card elevation="0" outlined class="fliter-wrap">
          <div class="filter-details">
            <div class="d-flex filter-sec">
              <div class="d-flex">
                <v-icon class="mr-2">mdi-filter-variant</v-icon>
                <h4 class="name">Filter Results</h4>
              </div>
              <v-chip class="reset" @click.prevent="resetFilters">
                Reset filters
              </v-chip>
            </div>
            <div class="header">Fixture Date</div>
            <div class="date d-flex align-center justify-center mr-2">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateUkFormat(filterPayload.fixture_start_date)"
                    hide-details="auto"
                    solo
                    class="date-input"
                    v-on="on"
                    v-bind="attrs"
                    placeholder="Date from"
                  >
                    <template slot="prepend-inner">
                      <img
                        class="calendar"
                        src="@/assets/images/calendar.svg"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filterPayload.fixture_start_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    :ripple="false"
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :ripple="false"
                    text
                    color="primary"
                    @click="$refs.menu.save(filterPayload.fixture_start_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="dateUkFormat(filterPayload.fixture_end_date)"
                    hide-details="auto"
                    solo
                    class="date-input"
                    v-bind="attrs"
                    v-on="on"
                    placeholder="Date to"
                  >
                    <template slot="prepend-inner">
                      <img
                        class="calendar"
                        src="@/assets/images/calendar.svg"
                      />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="filterPayload.fixture_end_date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    :ripple="false"
                    text
                    color="primary"
                    @click="menu2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    :ripple="false"
                    color="primary"
                    @click="$refs.menu2.save(filterPayload.fixture_end_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
          <v-divider class="line"></v-divider>
          <v-expansion-panels
            class="expansion"
            flat
            v-model="filterPanel"
            multiple
          >
            <template v-for="(item, key, index) in fixturesFilters">
              <v-expansion-panel
                v-if="key !== 'home_match' && key !== 'away_match'"
                expand
                class="filter-panel"
                :key="index"
              >
                <v-expansion-panel-header class="header">
                  {{ key | capitalize }}
                  <template v-slot:actions>
                    <v-icon
                      v-if="filterPanel.indexOf(index) > -1"
                      color="#070B32"
                    >
                      mdi-minus
                    </v-icon>
                    <v-icon
                      v-if="filterPanel.indexOf(index) < 0"
                      color="#070B32"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="filter-content">
                  <div
                    class="filter-checkbox d-flex align-center justify-space-between"
                    v-for="(list, ind) in fixturesFilters[key]"
                    :key="ind + key"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        :color="true ? '#070B32' : '#D3D5D7'"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-checkbox-outline"
                        :ripple="false"
                        @change="setFilters(key, list.id)"
                      ></v-checkbox>
                      <span>{{ list.name | capitalize }}</span>
                    </div>
                    <div class="count" v-if="list.total">
                      ({{ list.total }})
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="key === 'home_match'"
                expand
                class="filter-panel"
                :key="index + key"
              >
                <v-expansion-panel-header class="header">
                  Home or Away
                  <template v-slot:actions>
                    <v-icon
                      v-if="filterPanel.indexOf(index) > -1"
                      color="#070B32"
                    >
                      mdi-minus
                    </v-icon>
                    <v-icon
                      v-if="filterPanel.indexOf(index) < 0"
                      color="#070B32"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="filter-content">
                  <div
                    class="filter-checkbox d-flex align-center justify-space-between"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="filterPayload.home_match"
                        :color="true ? '#070B32' : '#D3D5D7'"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-checkbox-outline"
                        :ripple="false"
                      ></v-checkbox>
                      <span> Home Matches </span>
                    </div>
                    <div class="count">
                      ({{ fixturesFilters["home_match"] }})
                    </div>
                  </div>
                  <div
                    class="filter-checkbox d-flex align-center justify-space-between"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="filterPayload.away_match"
                        :color="true ? '#070B32' : '#D3D5D7'"
                        off-icon="mdi-checkbox-blank-outline"
                        on-icon="mdi-checkbox-outline"
                        :ripple="false"
                      ></v-checkbox>
                      <span> Away Matches </span>
                    </div>
                    <div class="count">
                      ({{ fixturesFilters["away_match"] }})
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-expansion-panels>
        </v-card>
        <v-card elevation="0" class="sellticket">
          <div class="d-flex px-0 align-center tag-sec">
            <img class="tagpin mr-2" src="@/assets/images/tagpin.png" alt="" />
            <div class="tag-title">Do you have a ticket to sell?</div>
          </div>
          <div>
            <p class="card-info mb-0">
              Want to list your tickets on our site? Click through to find out
              more
            </p>
          </div>
          <div class="ticket">
            <v-btn
              to="/sell-your-tickets"
              :ripple="false"
              outlined
              class="btn-sellticket"
            >
              Sell Your Tickets
              <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
            /></v-btn>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" class="upcoming-events bg-grey-light">
        <div class="about-matches">
          <h3 class="name">
            Upcoming
            {{
              $store.state.teams.teamDetails
                ? $store.state.teams.teamDetails.name
                : ""
            }}
            Matches
          </h3>
          <template v-if="fixtures.length">
            <div class="">
              <event-card
                class="mb-2"
                v-for="item in fixtures"
                :key="item.id"
                :event-info="item"
              />
            </div>
            <div class="text-center">
              <v-pagination
                class="upcoming-pagination"
                v-model="filterPayload.page"
                :length="fixturesPagination.last_page"
              ></v-pagination>
            </div>
          </template>
          <template v-if="loading === false && fixtures.length === 0">
            <no-records-found type="team" />
          </template>
          <template v-if="!fixtures">
            <v-skeleton-loader
              v-for="item in 8"
              :key="item"
              class="mx-auto"
              type="list-item-avatar-three-line, divider"
            ></v-skeleton-loader>
          </template>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import EventCard from "./EventMolecules/EventCard.vue";
import DateFormat from "@/mixins/Dates/ukFormat.js";
import NoRecordsFound from "@/components/NoRecordsFound.vue";
export default {
  components: { EventCard, NoRecordsFound },
  name: "TheUpcomingMatches",
  mixins: [DateFormat],
  data: (vm) => ({
    loading: false,
    menu: false,
    menu2: false,
    navigationTab: null,
    page: 1,
    dateFrom: "",
    dateTo: "",
    filterPanel: [0, 1],
    filterPayload: {
      slug: vm.$route.params.teamSlug,
      page: 1,
      per_page: 10,
      tournaments: [],
      home_match: "",
      away_match: "",
      fixture_start_date: "",
      fixture_end_date: "",
    },
  }),
  watch: {
    "$store.state.teams.teamDetails": {
      handler: function () {
        this.loadFiltersDetails();
      },
      deep: true,
      immediate: true,
    },
    "$route.params": {
      handler: function (val) {
        this.filterPayload.slug = val.teamSlug;
        this.loadFiltersDetails();
        this.loadTeamsFixtures();
      },
      deep: true,
      immediate: true,
    },
    filterPayload: {
      handler: function () {
        this.loadTeamsFixtures();
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loadFiltersDetails();
  },
  computed: {
    fixtures() {
      return this.$store.state.teams.teamsFixtures;
    },
    fixturesPagination() {
      return this.$store.state.teams.teamsFixturesPagination;
    },
    fixturesFilters() {
      return this.$store.state.fixtures.fixturesFilters;
    },
  },
  methods: {
    async loadTeamsFixtures() {
      this.loading = true;
      await this.$store.dispatch(
        "teams/GET_TEAMS_FIXTURES",
        this.filterPayload
      );
      this.loading = false;
    },
    async loadFiltersDetails() {
      if (this.$store.state.teams.teamDetails) {
        await this.$store.dispatch("fixtures/GET_FIXTURES_FILTERS", {
          getters: ["tournament", "home_match", "away_match"],
          filters: {
            team_id: [this.$store.state.teams.teamDetails.id],
          },
        });
      }
    },
    async resetFilters() {
      this.filterPayload = {
        slug: this.$route.params.teamSlug,
        page: 1,
        per_page: 10,
        tournaments: [],
        home_match: "",
        away_match: "",
        fixture_start_date: "",
        fixture_end_date: "",
      };
      await this.loadTeamsFixtures();
    },
    async setFilters(key, id) {
      const index = this.filterPayload.tournaments.indexOf(id);
      index >= 0
        ? this.filterPayload.tournaments.splice(index, 1)
        : this.filterPayload.tournaments.push(id);
      await this.loadTeamsFixtures();
    },
  },
};
</script>
<style></style>
