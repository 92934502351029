<template>
  <div class="teams-wrap">
    <div v-if="stadiumDetails" class="teams-banner">
      <v-img :src="stadiumDetails.image" :alt="stadiumDetails.name">
        <div v-if="stadiumDetails.stadium_team" class="d-flex banner-topnav">
          <div class="map-location">
            <v-icon color="#0D0033" size="14" class="mr-2">
              mdi-map-marker
            </v-icon>
            {{ stadiumDetails.city_name | capitalize }}
            {{ stadiumDetails.country_name | capitalize }}
            {{ stadiumDetails.stadium_name }} | Capacity:
            {{ stadiumDetails.capacity }} –
            <a href="#nearby" class="link"> See what’s nearby </a>
          </div>
        </div>
        <div class="teams-name-wrapper">
          <v-card class="team-info" elevation="0">
            <div class="d-flex align-center justify-center">
              <div class="logo logo-wrap mr-4">
                <img
                  v-if="stadiumDetails.stadium_team"
                  class=""
                  :src="stadiumDetails.stadium_team.logo"
                  :alt="stadiumDetails.name"
                />
              </div>
              <div class="team-names">
                {{ stadiumDetails.name }}
              </div>
            </div>
          </v-card>
        </div>
        <!-- <v-card
          class="d-flex team-info align-center justify-center"
          elevation="0"
        >
          <img
            v-if="stadiumDetails.stadium_team"
            class="logo mr-4"
            :src="stadiumDetails.stadium_team.logo"
            :alt="stadiumDetails.name"
          />
          <div class="team-names">
            {{ stadiumDetails.name }}
          </div>
        </v-card> -->
      </v-img>
    </div>
    <div v-else class="teams-wrap">
      <v-skeleton-loader
        height="500"
        v-bind="{ boilerplate: false }"
        type="image"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  name: "StadiumBanner",
  computed: {
    stadiumDetails() {
      return this.$store.state.stadiums.details;
    },
  },
};
</script>
<style></style>
