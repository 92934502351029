<template>
  <div>
    <template v-if="info">
      <div v-if="info.public_properties.length" class="history">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h3 class="name">
                {{ info.title ? info.title : info.name }} History & Highlights
              </h3>
              <v-container fluid>
                <v-row class="sm-px-8 sm-py-8">
                  <v-col v-if="info.trivia_image" cols="12" md="3" sm="6">
                    <div class="team-img">
                      <!-- <img class="t-shirtimg" src="@/assets/images/t-shirt.png" alt="" /> -->
                      <img
                        class="t-shirtimg"
                        :src="info.trivia_image"
                        :alt="info.name"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    sm="6"
                    v-for="i in Math.ceil(info.public_properties.length / 5)"
                    :key="i"
                  >
                    <div
                      v-for="ppItem in info.public_properties.slice(
                        (i - 1) * 5,
                        i * 5
                      )"
                      :key="ppItem.id"
                      class="category pb-4"
                    >
                      <div
                        v-if="ppItem.key === 'Website'"
                        class="d-flex justify-space-between"
                      >
                        <div class="key-ttl">{{ ppItem.key }}:</div>
                        <div class="val-ttl">
                          <a class="data" target="_blank" :href="ppItem.value">
                            {{ ppItem.value }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-else-if="ppItem.key === 'Phone'"
                        class="d-flex justify-space-between"
                      >
                        <div class="key-ttl">{{ ppItem.key }}:</div>
                        <div class="val-ttl">
                          <a
                            class="data"
                            target="_blank"
                            :href="`tel:${ppItem.value}`"
                          >
                            {{ ppItem.value }}
                          </a>
                        </div>
                      </div>
                      <div v-else class="d-flex justify-space-between">
                        <div class="key-ttl">{{ ppItem.key }}:</div>
                        <div class="data val-ttl">{{ ppItem.value }}</div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
        <v-divider class="line"></v-divider>
      </div>
      <div v-if="info.trivia_description" class="hightlights">
        <v-row>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="9">
            <v-card class="team-achievements">
              <div class="d-flex align-center mb-6">
                <img
                  class="mr-4 markimg"
                  src="@/assets/images/person-question-mark.png"
                  alt=""
                />
                <h4 class="name">
                  {{ info.title ? info.title : info.name }} fun facts & trivia:
                </h4>
              </div>
              <div class="funfact" v-html="info.trivia_description"></div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-else>
      <v-skeleton-loader type="card"></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
export default {
  name: "TheTeamsHistory",
  props: {
    info: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    teamDetails() {
      return this.$store.state.teams.teamDetails;
    },
  },
};
</script>
<style></style>
