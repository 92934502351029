<template>
  <div class="container--fluid pa-0">
    <the-header />
    <banner-hero :details="$store.state.stadiums.details" />
    <scrollactive class="h-menu" :offset="130">
      <a href="#upcoming-matches" class="scrollactive-item">Upcoming Matches</a>
      <a href="#stadium-info" class="scrollactive-item">Stadium Information</a>
      <a href="#faq" class="scrollactive-item">FAQs</a>
    </scrollactive>
    <!-- <div class="h-menu">
      <ul>
        <li><a class="active" href="#upcoming-matches">Upcoming Matches</a></li>
        <li><a href="#stadium-info">Stadium Information</a></li>
        <li><a href="#faq">FAQs</a></li>
      </ul>
    </div> -->
    <v-divider class="line"></v-divider>
    <div class="container pt-0" id="upcoming-matches">
      <div v-if="$store.state.stadiums.details" class="manchester-team">
        <v-row class="ma-0">
          <v-col cols="12" md="4" class="filter-and-sellticket">
            <filter-results
              type="stadium"
              :id="$store.state.stadiums.details.id"
            />
            <sell-your-ticket-card />
          </v-col>
          <v-col cols="12" md="8" class="upcoming-events bg-grey-light">
            <upcoming-fixtures-by-filter
              :title="`Upcoming ${$store.state.stadiums.details.name} Matches`"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="container--fluid bg-grey-light" id="stadium-info">
      <the-features />
      <teams-tickets-on-sale :info="$store.state.stadiums.details" />
    </div>
    <div class="container" id="stadium-history">
      <the-teams-history :info="$store.state.stadiums.details" />
    </div>
    <div class="container--fluid" id="stadium-info">
      <location-cars-and-nearby
        :stadiumDetails="$store.state.stadiums.details"
      />
    </div>
    <div class="container" id="faq">
      <teams-info :info="$store.state.stadiums.details" />
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import BannerHero from "../components/Banners/StadiumBanner.vue";
import FilterResults from "@/components/FilterResults.vue";
import SellYourTicketCard from "@/components/SellYourTicketCard.vue";
import UpcomingFixturesByFilter from "@/components/UpcomingFixturesByFilter.vue";
import TheFeatures from "../components/TheFeatures.vue";
import TeamsTicketsOnSale from "../components/TeamsTicketsOnSale.vue";
import TheTeamsHistory from "../components/TheTeamsHistory.vue";
import TeamsInfo from "@/components/Stadiums/NearByStadiums.vue";
import TheFooter from "../components/TheFooter.vue";
import LocationCarsAndNearby from "@/components/Stadiums/LocationCarsAndNearby.vue";
export default {
  name: "Stadiums",
  data: () => ({
    loading: true,
  }),
  components: {
    TheHeader,
    BannerHero,
    UpcomingFixturesByFilter,
    FilterResults,
    SellYourTicketCard,
    TheFeatures,
    TeamsTicketsOnSale,
    TheTeamsHistory,
    TeamsInfo,
    TheFooter,
    LocationCarsAndNearby,
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.fetchData(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async fetchData(val) {
      this.loading = true;
      await this.$store.dispatch("stadiums/GET_DETAILS", {
        slug: val.stadiumSlug,
      });
      await this.$store.dispatch("stadiums/GET_NEARBY_TEAMS", {
        slug: val.stadiumSlug,
      });
      await this.$store.dispatch("stadiums/GET_NEARBY_STADIUMS", {
        slug: val.stadiumSlug,
      });
      this.loading = false;
    },
  },
};
</script>
<style></style>
