<template>
  <div class="container--fluid pa-0">
    <the-header />
    <teams-banner />
    <scrollactive class="my-nav h-menu" :offset="130">
      <a href="#upcoming-matches" class="scrollactive-item">
        Upcoming Matches
      </a>
      <a href="#team-info" class="scrollactive-item">Team Info</a>
      <a href="#team-history" class="scrollactive-item">Team History</a>
      <a href="#faq" class="scrollactive-item">FAQs</a>
    </scrollactive>
    <div class="container pt-0" id="upcoming-matches">
      <teams-upcoming-matches />
    </div>
    <div class="container--fluid bg-grey-light" id="team-info">
      <the-features />
      <teams-tickets-on-sale :info="$store.state.teams.teamDetails" />
    </div>
    <div class="container" id="team-history">
      <the-teams-history :info="$store.state.teams.teamDetails" />
    </div>
    <div class="container" id="faq">
      <teams-info :info="$store.state.teams.teamDetails" />
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import TeamsBanner from "../components/TeamsBanner.vue";
import TeamsUpcomingMatches from "../components/TeamsUpcomingMatches.vue";
import TheFeatures from "../components/TheFeatures.vue";
import TeamsTicketsOnSale from "../components/TeamsTicketsOnSale.vue";
import TheTeamsHistory from "../components/TheTeamsHistory.vue";
import TeamsInfo from "../components/TeamsInfo.vue";
import TheFooter from "../components/TheFooter.vue";
export default {
  name: "Teams",
  components: {
    TheHeader,
    TeamsBanner,
    TeamsUpcomingMatches,
    TheFeatures,
    TeamsTicketsOnSale,
    TheTeamsHistory,
    TeamsInfo,
    TheFooter,
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.$store.dispatch("teams/GET_TEAM_DETAILS", { slug: val.teamSlug });
        this.$store.dispatch("teams/GET_NEARBY_TEAMS", { slug: val.teamSlug });
        this.$store.dispatch("teams/GET_NEARBY_STADIUMS", {
          slug: val.teamSlug,
        });
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style></style>
