<template>
  <div class="ftl__widget">
    <v-card class="ftl__widget__card pa-5" elevation="0" outlined>
      <div class="pop-team d-flex">
        <div class="d-flex align-center">
          <img
            class="stadimg"
            src="@/assets/images/fire.svg"
            alt="nearby-teams"
          />
          <div>Nearby Teams</div>
        </div>
        <!-- <div>
          <v-btn :ripple="false" text class="view-all">
            View all
            <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
          /></v-btn>
        </div> -->
      </div>
      <div v-if="teamsInfo.length" class="ftl__widget--chip-group">
        <v-chip
          class="ftl__widget--chip mr-2 mb-2"
          v-for="(item, index) in teamsInfo"
          :key="`${item.id}+${index}`"
          :to="`/teams/${item.slug}`"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <div v-else class="ftl__widget--chip-group">There are no records</div>
    </v-card>
    <v-card
      v-if="stadiumsInfo"
      class="ftl__widget__card pa-5"
      elevation="0"
      outlined
    >
      <div class="pop-team d-flex">
        <div class="d-flex align-center">
          <img
            class="img mr-3"
            src="@/assets/images/stadium.svg"
            alt="nearby-stadiums"
          />
          <div>Nearby Stadiums</div>
        </div>
        <!-- <div>
          <v-btn :ripple="false" text class="view-all">
            View all
            <img class="ml-1 mt-1" src="@/assets/images/blue-arrow.svg"
          /></v-btn>
        </div> -->
      </div>
      <div v-if="stadiumsInfo.length" class="ftl__widget--chip-group">
        <v-chip
          class="ftl__widget--chip mr-2 mb-2"
          v-for="(item, index) in stadiumsInfo"
          :key="`${item.id}+${index}`"
          :to="`/stadiums/${item.slug}`"
        >
          {{ item.name }}
        </v-chip>
      </div>
      <div v-else class="ftl__widget--chip-group">There are no records</div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "TeamsNearbyInfoMolecules",
  props: {
    teamsInfo: {
      Type: Array,
      default: [],
    },
    stadiumsInfo: {
      Type: Array,
      default: [],
    },
  },
};
</script>
<style></style>
