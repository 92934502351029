<template>
  <div v-if="info" class="ftl__expansion__sec">
    <div class="header">
      <h2 class="title">{{ teamName }} ticket information</h2>
    </div>
    <v-card class="ftl__expansion__row" v-model="panel" elevation="0">
      <v-expansion-panels v-model="panel" class="ftl__expansion" flat>
        <template v-for="(item, index) in items">
          <v-expansion-panel expand class="ftl__expansion__panel" :key="index">
            <v-expansion-panel-header class="ftl__expansion__header">
              {{ item.question }}
              <template v-slot:actions>
                <v-icon color="#070B32"> mdi-plus </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="ftl__expansion__content">
              <div class="d-block" v-html="item.answer"></div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "FAQ",
  props: {
    info: {
      Type: Object,
      required: true,
    },
  },
  computed: {
    teamName() {
      // return this.info.title;
      if (this.info) {
        if (this.info.title) return this.info.title;
        if (this.info.name) return this.info.name;
      }
      return "";
    },
  },
  data: () => ({
    loading: false,
    panel: 0,
    items: [
      {
        question: `Are my tickets valid? `,
        answer: `<p>Yes, all tickets purchased through <strong>https://footballticketslive.com</strong> are 100% valid and guaranteed.</p>`,
      },
      {
        question: `How will I receive my tickets?`,
        answer: `<p>Tickets are usually delivered by post, shipping courier, hand delivery, pick-up point, box office collection, or electronically. It is not an option for customers to decide it but for us in order to get them delivered on time. The delivery information will be sent by email in due time and updated on your account on our site.</p>`,
      },
      {
        question: `I have not received a confirmation email for my order, what should I do?`,
        answer: `<p>First, please check your spam/junk folders to be sure it was not misdirected. If you don't find it, contact us to be sure your email address is working properly for us to communicate with you. You can also download and print your order confirmation from your client account.</p>`,
      },
      {
        question: `Will I be seated in the Home or Away section?`,
        answer: `<p>Purchased tickets are always seated with the home team unless they are specifically purchased as away tickets or in the "any available" category (any available means anywhere so it can be either home or away).
FootballTicketsLive</p>`,
      },
    ],
  }),
};
</script>
<style></style>
