<template>
  <div class="teams-wrap">
    <div v-if="tournamentDetails" class="teams-banner">
      <v-img
        src="@/assets/images/tournament-banner.png"
        :alt="tournamentDetails.title"
      >
        <div v-if="tournamentDetails.logo" class="d-flex banner">
          <div class="map-location">
            <v-icon color="#0D0033" size="14" class="mr-2">
              mdi-map-marker
            </v-icon>
            {{ tournamentDetails.stadium_name }} | Capacity:
            {{ tournamentDetails.stadium_capacity }} –
            <a class="link"> See what’s nearby </a>
          </div>
        </div>
        <div class="teams-name-wrapper">
          <v-card class="team-info" elevation="0">
            <div class="d-flex align-center justify-center">
              <img
                class="logo mr-4"
                :src="tournamentDetails.image"
                :alt="tournamentDetails.title"
              />
              <div class="team-names">
                {{ tournamentDetails.title }}
              </div>
            </div>
          </v-card>
        </div>

        <!-- <v-card
          class="d-flex team-info align-center justify-center"
          elevation="0"
        >
          <img
            class="logo mr-4"
            :src="tournamentDetails.image"
            :alt="tournamentDetails.title"
          />
          <div class="team-names">
            {{ tournamentDetails.title }}
          </div>
        </v-card> -->
      </v-img>
    </div>
    <div v-else class="teams-wrap">
      <v-skeleton-loader
        height="500"
        v-bind="{ boilerplate: false }"
        type="image"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
export default {
  name: "TournamentBanner",
  computed: {
    tournamentDetails() {
      return this.$store.state.tournaments.details;
    },
  },
};
</script>
<style></style>
