<template>
  <div class="container ftl__expansion__wrap">
    <v-row>
      <v-col cols="12" md="7">
        <teams-ticket-info :info="info" />
      </v-col>
      <v-col cols="12" md="5">
        <teams-near-by-info
          :teams-info="nearbyTeams"
          :stadiums-info="nearbyStadiums"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TeamsTicketInfo from "../TeamsTicketInfoMolecules/TeamsTicketInfo.vue";
import TeamsNearByInfo from "../TeamsTicketInfoMolecules/TeamsNearByInfo.vue";
export default {
  name: "TeamsInfo",
  components: { TeamsTicketInfo, TeamsNearByInfo },
  props: {
    info: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    nearbyTeams() {
      return this.$store.state.stadiums.nearByTeams;
    },
    nearbyStadiums() {
      return this.$store.state.stadiums.nearByStadiums;
    },
  },
};
</script>
<style></style>
