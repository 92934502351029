<template>
  <div class="container--fluid pa-0">
    <the-header />
    <tournament-banner />
    <scrollactive class="h-menu" :offset="130">
      <a href="#upcoming-matches" class="scrollactive-item">Upcoming Matches</a>
      <a href="#stadium-info" class="scrollactive-item">League Info</a>
      <a href="#faq" class="scrollactive-item">FAQs</a>
    </scrollactive>
    <!-- <div class="h-menu">
      <ul>
        <li><a class="active" href="#upcoming-matches">Upcoming Matches</a></li>
        <li><a href="#tournament-info">League Info</a></li>
        <li><a href="#faq">FAQs</a></li>
      </ul>
    </div> -->
    <v-divider class="line"></v-divider>
    <div class="container pt-0" id="upcoming-matches">
      <div v-if="$store.state.tournaments.details" class="manchester-team">
        <v-row class="ma-0">
          <v-col cols="12" md="4" class="filter-and-sellticket">
            <filter-results
              type="tournament"
              :id="$store.state.tournaments.details.id"
            />
            <sell-your-ticket-card />
          </v-col>
          <v-col cols="12" md="8" class="upcoming-events bg-grey-light">
            <upcoming-fixtures-by-filter
              :title="`Upcoming ${$store.state.tournaments.details.title} Matches`"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="container--fluid bg-grey-light" id="tournament-info">
      <the-features />
      <teams-tickets-on-sale :info="$store.state.tournaments.details" />
      <the-teams-history :info="$store.state.tournaments.details" />
    </div>
    <div class="container" id="faq">
      <teams-info :info="$store.state.tournaments.details" />
    </div>
    <the-footer />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader.vue";
import TournamentBanner from "../components/Banners/TournamentBanner.vue";
import FilterResults from "@/components/FilterResults.vue";
import SellYourTicketCard from "@/components/SellYourTicketCard.vue";
import UpcomingFixturesByFilter from "@/components/UpcomingFixturesByFilter.vue";
import TheFeatures from "../components/TheFeatures.vue";
import TeamsTicketsOnSale from "../components/TeamsTicketsOnSale.vue";
import TheTeamsHistory from "../components/TheTeamsHistory.vue";
import TeamsInfo from "../components/TeamsInfo.vue";
import TheFooter from "../components/TheFooter.vue";
export default {
  name: "Tournaments",
  data: () => ({
    loading: true,
  }),
  components: {
    TheHeader,
    TournamentBanner,
    UpcomingFixturesByFilter,
    FilterResults,
    SellYourTicketCard,
    TheFeatures,
    TeamsTicketsOnSale,
    TheTeamsHistory,
    TeamsInfo,
    TheFooter,
  },
  watch: {
    "$route.params": {
      handler: function (val) {
        this.fetchData(val);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    async fetchData(val) {
      this.loading = true;
      await this.$store.dispatch("tournaments/GET_TOURNAMENT_DETAILS", {
        slug: val.tournamentSlug,
      });
      await this.$store.dispatch("teams/GET_NEARBY_TEAMS", {
        slug: val.tournamentSlug,
      });
      await this.$store.dispatch("teams/GET_NEARBY_STADIUMS", {
        slug: val.tournamentSlug,
      });
      this.loading = false;
    },
  },
};
</script>
<style></style>
